<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-9">


            <div *ngIf="selectedType=='CALLBACKREQUEST'|| selectedType=='CALL_911'">
                <div class=" card patient-card">
                    <div class="row patient-location-wrapper">
                        <div class="col ">
                            <app-patient-location-tracker [isBordered]="true" [locationValueTruncate]="true">
                            </app-patient-location-tracker>
                        </div>
                    </div>
                    <div class="row patient-info-section">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <div class="row profile-info-wrapper">
                                        <div class="col gravatar-col">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="gravatar-wrapper">
                                                        <span class="profile-gravatar">
                                                            {{patient?.firstName + ' ' + patient?.lastName |
                                                            nameToInitials}}
                                                        </span>
                                                        <span class="tk-online-status">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row profile-badge-wrapper">
                                                <div class="col">
                                                    <span class="tk-badge-patient text-uppercase">
                                                        Patient
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col">
                                            <div class="profile-name-wrapper">
                                                <span class="profile-name">
                                                    {{patient?.firstName + ' ' + patient?.lastName}}
                                                </span>
                                                <span class="tk-badge"
                                                    [ngStyle]="{'backgroundColor':patient?.crsAssigned.bgColor, 'color': patient?.crsAssigned.textColor }">
                                                    {{patient?.crsAssigned?.text}}
                                                </span>
                                            </div>
                                            <ul class="profile-personal-info-list">
                                                <li>
                                                    {{patient?.gender | titlecase}}<span *ngIf="patient?.age > 0">{{',' +patient?.age}} Yrs</span>
                                                </li>
                                                <li>
                                                    {{patient?.dob | date : 'dd MMM yyyy'}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row patient-actions-wrapper">
                                <div class="col">
                                    <p>has requested a callback 2 mins ago</p>
                                    <div *ngIf="selectedType=='CALLBACKREQUEST'">
                                        <p *ngIf="showButton==1">
                                            <button class="btn tk-btn tk-orange-btn-light tk-lg-btn"
                                                (click)="attendingCall()">
                                                Attending this Call
                                            </button>
                                        </p>
                                        <p *ngIf="showButton==0">
                                            <button class="btn tk-btn tk-orange-btn-light tk-lg-btn"
                                                (click)="addRemark()">
                                                Add Remark
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row tk-header-wrapper">
                                <div class="col">
                                    <h6 class="tk-info-header title-with-border">Information</h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="row patient-data-wrapper">
                                        <div class="col-12">
                                            <ul class="profile-info-list">
                                                <li>
                                                    <label class="tk-info-label">Id</label>
                                                    <p class="tk-info-value">{{patient?.patientID | nullToDash}}</p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Email Id</label>
                                                    <p class="tk-info-value"> <a class="mail-link"
                                                            href="mailto:{{patient?.email}}">
                                                            {{patient?.email | nullToDash}}
                                                        </a></p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Mobile Number</label>
                                                    <p class="tk-info-value"><a class="tel-link"
                                                            href="tel:{{patient?.mobileNo}}">
                                                            {{patient?.mobileNo | nullToDash}}
                                                        </a></p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Address</label>
                                                    <p class="tk-info-value tk-address">
                                                        {{patient?.address + ' ' + patient?.city + ' '+ patient?.pincode
                                                        +' ' + patient?.state + ' ' + patient?.country | nullToDash}}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-12">
                                            <ul class="profile-info-list">
                                                <li>
                                                    <label class="tk-info-label">Health Insurance</label>
                                                    <p class="tk-info-value">
                                                        {{patient?.haveHealthInsurance ? 'yes' : 'no' | titlecase}}
                                                    </p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Social Security Number</label>
                                                    <p class="tk-info-value">
                                                        {{patient?.ssn | nullToDash}}
                                                    </p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Description</label>
                                                    <p class="tk-info-value">
                                                        {{patient?.description | nullToDash}}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div *ngIf="selectedType=='CHAT'">
                <div class="card patient-card">
                    <div class="row patient-location-wrapper">
                        <div class="col">
                            <app-patient-location-tracker [isBordered]="true" [locationValueTruncate]="true">
                            </app-patient-location-tracker>
                        </div>
                    </div>
                    <div class="row patient-info-section">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <div class="row profile-info-wrapper">
                                        <div class="col gravatar-col">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="gravatar-wrapper">
                                                        <span class="profile-gravatar">
                                                            {{patient?.firstName + ' ' + patient?.lastName |
                                                            nameToInitials}}
                                                        </span>
                                                        <span class="tk-online-status">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row profile-badge-wrapper">
                                                <div class="col">
                                                    <span class="tk-badge-patient text-uppercase">
                                                        Patient
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col">
                                            <div class="profile-name-wrapper">
                                                <span class="profile-name">
                                                    {{patient?.firstName + ' ' + patient?.lastName}}
                                                </span>
                                                <span class="tk-badge"
                                                    [ngStyle]="{'backgroundColor':patient?.crsAssigned.bgColor, 'color': patient?.crsAssigned.textColor }">
                                                    {{patient?.crsAssigned?.text}}
                                                </span>
                                            </div>
                                            <ul class="profile-personal-info-list">
                                                <li>
                                                    {{patient?.gender | titlecase}}<span *ngIf="patient?.age > 0">{{',' +patient?.age}} Yrs</span>
                                                </li>
                                                <li>
                                                    {{patient?.dob | date : 'dd MMM yyyy'}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row patient-actions-wrapper">
                                <div class="col">
                                    <p>has requested a callback 2 mins ago</p>
                                    <p>
                                        <button class="btn tk-btn tk-orange-btn-light tk-lg-btn"
                                            (click)="chekTimeout(patient.patientID)">
                                            Join Chat
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row tk-header-wrapper">
                                <div class="col">
                                    <h6 class="tk-info-header title-with-border">Information</h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="row patient-data-wrapper">
                                        <div class="col-12">
                                            <ul class="profile-info-list">
                                                <li>
                                                    <label class="tk-info-label">Id</label>
                                                    <p class="tk-info-value">{{patient?.patientID | nullToDash}}</p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Email Id</label>
                                                    <p class="tk-info-value"> <a class="mail-link"
                                                            href="mailto:{{patient?.email}}">
                                                            {{patient?.email | nullToDash}}
                                                        </a></p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Mobile Number</label>
                                                    <p class="tk-info-value"><a class="tel-link"
                                                            href="tel:{{patient?.mobileNo}}">
                                                            {{patient?.mobileNo | nullToDash}}
                                                        </a></p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Address</label>
                                                    <p class="tk-info-value tk-address">
                                                        {{patient?.address + ' ' + patient?.city + ' '+ patient?.pincode
                                                        +' ' + patient?.state + ' ' + patient?.country | nullToDash}}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-12">
                                            <ul class="profile-info-list">
                                                <li>
                                                    <label class="tk-info-label">Health Insurance</label>
                                                    <p class="tk-info-value">
                                                        {{patient?.haveHealthInsurance ? 'yes' : 'no' | titlecase}}
                                                    </p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Social Security Number</label>
                                                    <p class="tk-info-value">
                                                        {{patient?.ssn | nullToDash}}
                                                    </p>
                                                </li>
                                                <li>
                                                    <label class="tk-info-label">Description</label>
                                                    <p class="tk-info-value">
                                                        {{patient?.description | nullToDash}}
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 side-pane-list">

            <div class="row pane-header">
                <div class="col">
                    <span class="tk-pane-header">
                        Group Alerts
                    </span>
                    <hr style="width:7%;text-align:left;margin-left:0;">

                    <span class="filter-btn">
                        <img src="assets/icons/filter-icon-white.svg" alt="filter"
                            (click)="openAlertFilterModal(filterNotification)">
                    </span>
                    <span class="search-btn">
                        <img src="assets/icons/search_icon.svg" alt="search">
                    </span>
                </div>
            </div>
            <div class="row pane-filter">
                <div class="col">
                    <span class="tk-switch-wrapper">
                        <app-tk-switch-self-all (directionEmitted)="trackCrsTypeSwitch($event)">
                        </app-tk-switch-self-all>
                    </span>
                </div>
            </div>

            <div class="row pane-list-wrapper">
                <div class="col">
                    <ul class="pane-list">
                        <ng-container *ngFor="let notification of notificationList">
                            <li>
                                <div class="card">
                                    <div class="row">
                                        <div class="col workdetails">
                                            <div class="">
                                                <div class="row">
                                                  <div class="col-8">
                                                    <div class="title">
                                                      <p *ngIf="notification.alertType=='CHAT'">Chat Request</p>
                                                      <p *ngIf="notification.alertType=='CALLBACKREQUEST'">Callback Request</p>
                                                      <p *ngIf="notification.alertType=='CALL_911'">Call 911 Alert</p>
                                                      <p *ngIf="notification.alertType=='HOTSPOT'">Hotspot Alert</p>
                                                      <span
                                                        [ngClass]="{
                                                          done: notification.alertStatus == 'PICKED',
                                                          notdone: notification.alertStatus == 'NOTPICKED'
                                                        }"
                                                      ></span>
                                                    </div>
                                                  </div>
                                                  <div class="col-4 text-right">
                                                    <div class="time">{{ notification.timeStamp }}</div>
                                                  </div>
                                                </div>
                                            </div>
                                            <p class="list-patient-info">
                                                {{notification.messageOne}}
                                            </p>
                                            <p class="list-patient-info">
                                                {{notification.messageTwo}}
                                            </p>

                                            <div class="text-right">

                                            <div *ngIf="notification.alertType == 'CALLBACKREQUEST'" (click)="
                                              goToCrsPatientInfo(
                                                notification.id,
                                                notification.alertId,
                                                notification.alertType
                                              )
                                            ">
                                                <p *ngIf="notification.alertStatus == 'NOTPICKED'">
                                                    View Request Detail
                                                </p>
                                                <p style="color: green" *ngIf="notification.alertStatus == 'PICKED'">
                                                    Request Accepted
                                                </p>
                                                <p style="color: red" *ngIf="notification.alertStatus == 'UNANSWERED'">
                                                    Missed
                                                </p>
                                                <!-- Call -->
                                            </div>

                                            <div class="view-all orange" *ngIf="notification.alertType == 'CHAT'"
                                                (click)="
                                              goToCrsPatientInfos(
                                                notification.id,
                                                notification.chatId,
                                                notification.alertId,
                                                notification.alertType
                                              )
                                            ">
                                                <p *ngIf="notification.alertStatus == 'NOTPICKED'">
                                                    View Request Detail
                                                </p>
                                                <p style="color: green" *ngIf="notification.alertStatus == 'PICKED'">
                                                    Request Accepted
                                                </p>
                                                <p style="color: red" *ngIf="notification.alertStatus == 'UNANSWERED'">
                                                    Missed
                                                </p>
                                                <!-- Join Chat -->
                                            </div>

                                            <div class="view-all" (click)="
                                                goToCrsPatientInfo(
                                                notification.id,
                                                notification.alertId,
                                                notification.alertType
                                                )
                                                " *ngIf="notification.alertType == 'CALL_911'">
                                                View Patient Detail
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <div class="container mb-3" *ngIf="totalGroupAlertsCount > 10">
                          <div class="row">
                            <div class="col-12 my-3 text-center">
                              <!-- [disabled]="disabledOnce == true" -->
                              <button
                                class="btn-load-more-pagination"
                                (click)="loadMoreData()"
                                *ngIf="showLoad"
                              >
                                Load More
                              </button>
                            </div>
                          </div>
                        </div>
                        <li class="no_more_live_request-item" *ngIf="notificationList.length===0">
                            <span class="no_more_live_request-img">
                                <img src="assets/img/no_more_live_request.svg" alt="no_more_live_request">
                            </span>
                            <p class="tk-default-text">
                                No Notifications Found!
                            </p>
                        </li>
                    </ul>
                </div>
            </div>


            <ng-template #filterNotification>
                <div class="modal-body text-left">
                    <button type="button" class="close right-cornerbtn" aria-label="Close" (click)="closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        <p class="title">Filter Alert Type</p>
                    </div>
                    <div>
                        <p class="subtitle">Show alert by selected type</p>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                            [checked]="isAllChecked" (click)="checkAll((ischecked = !ischecked))" checked>
                        <label class="form-check-label" for="flexCheckDefault">
                            All
                        </label>
                    </div>

                    <div class="form-check" *ngFor="let item of memberFilterList; let i = index">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                            [checked]="item.isChecked" id="defaultChecked" (change)="selectRow(item, $event)">
                        <label class="form-check-label" for="flexCheckDefault">
                            {{item.name}}
                        </label>
                    </div>


                    <div>
                        <div class="row">
                            <div class="col-sm-6 col-md-offset-2" style="text-align: center;">
                                <button type="button" class="btn btn-light" (click)="clearSelectedMemberId()">Clear
                                    Filter</button>
                            </div>
                            <div class="col-sm-6" style="text-align: center;">
                                <button type="button" class="btn btn-danger"
                                    (click)="applySelectedFilterMemberId()">Apply</button>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>