import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
    AbstractControl,
  } from '@angular/forms';
  
  export class CustomValidators {
    noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  
    validatePinCode(input: FormControl) {
      return /^[1-9][0-9]*$/.test(input.value) ? null : { pincode: true };
    }
    validateEmail(input: FormControl) {
      return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/.test(input.value)
        ? null
        : { email: true };
    }
    validateNumberOnly(input: FormControl) {
      return /^[0-9]*$/.test(input.value) ? null : { text: true };
    }
    validateMobileNumber(input: FormControl) {
      return /^([- +()0-9])*$/.test(input.value) ? null : { mobile: true };
      // return /^([7-9]{1}[0-9]{9})*$/.test(input.value) ? null : { mobile: true };
    }
  
    validateAlphabet(input: FormControl) {
      return /^[a-zA-Z\s]*$/.test(input.value) ? null : { name: true };
    }
  
    validatePassword(input: FormControl) {
      return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}/.test(
        input.value
      )
        ? null
        : { pwd: true };
    }
  }
  