import { Component, OnInit } from '@angular/core';
import { MenuItems, MenuItemsMaker } from '../../models/menu-items/menu-items.model';
import { menuItemsArray } from 'src/app/modules/core/mocks/menu-items';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  menuList: Array<MenuItems>;
  currentRoute: string;
  username: string;

  isProfileMenuOpenedSub: Subject<boolean>;
  isProfileMenuOpened: boolean;

  isNavbarMenuOpenedSub: Subject<boolean>;
  isNavbarMenuOpened: boolean;
  isNotificationActive: boolean = false;
  activeChildUrl: boolean = false;
  parentUrl: any;
  userData: any;
  constructor(
    private route: Router,
    private userService: UserService
  ) {
    this.currentRoute = '/alerts';
    this.username = 'Sameer Mulani';
    this.menuList = [];
    this.buildMenuList();
    this.trackActiveRoute();

    this.isProfileMenuOpenedSub = new Subject();
    this.isNavbarMenuOpenedSub = new Subject();

    this.trackMenu();
  }

  ngOnInit(): void {
    this.userData = JSON.parse(this.userService.getUserDataFromLocal());
    console.log(this.userData);
  }

  buildMenuList = () => {
    this.menuList = menuItemsArray.map(menuItem => MenuItemsMaker.castInto(menuItem));
  }

  trackActiveRoute = () => {
    this.route.events.subscribe(events => {
      if (events instanceof NavigationStart) {
        this.closeProfileMenu();
        this.isNotificationActive = false;
        if (events.url.startsWith('/crs')) {
          this.activeChildUrl = true;
          this.parentUrl = '/crs';
        } 
        else if (events.url.startsWith('/patients')) {
          this.activeChildUrl = true;
          this.parentUrl = '/patients';
        }
        else if (events.url.startsWith('/hotspots')) {
          this.activeChildUrl = true;
          this.parentUrl = '/hotspots';
        }
        else if (events.url.startsWith('/callback-requests')) {
          this.activeChildUrl = true;
          this.parentUrl = '/callback-requests';
        }
        else if (events.url.startsWith('/chat-requests')) {
          this.activeChildUrl = true;
          this.parentUrl = '/chat-requests';
        }
        else {
          this.activeChildUrl = false;
          this.parentUrl = '';
        }
      }
      if (events instanceof NavigationEnd) {
        this.currentRoute = events.url.toLowerCase();
        this.isNotificationActive = false;
      }
    });
  }

  trackMenu = () => {
    this.isProfileMenuOpenedSub.subscribe(state => {
      this.isProfileMenuOpened = state;
    });
    this.isNavbarMenuOpenedSub.subscribe(state => {
      this.isNavbarMenuOpened = state;
    });
  }


  openProfileMenu = () => {
    this.isProfileMenuOpenedSub.next(true);
  }
  closeProfileMenu = () => {
    this.isProfileMenuOpenedSub.next(false);
  }
  toggleProfileMenu = () => {
    this.isProfileMenuOpenedSub.next(!this.isProfileMenuOpened);
  }

  openNavbarMenu = () => {
    this.isNavbarMenuOpenedSub.next(true);
  }
  closeNavbarMenu = () => {
    this.isNavbarMenuOpenedSub.next(false);
  }
  toggleNavbarMenu = () => {
    this.isNavbarMenuOpenedSub.next(!this.isNavbarMenuOpened);
  }

  onLogout = () => {
    this.userService.logout();
  }

  goToProfilePage = () => {
    this.route.navigate(['/profile']);
  }

  notificationBell() {
    // this.route.navigate(['/notifications']);
    this.isNotificationActive = !this.isNotificationActive;
  }
}
