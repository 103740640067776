<div class="row forgot-password-wrapper">
  <div class="col">
    <div class="row">
      <div class="col">
        <h5 class="tk-heading-5">Forgot Password?</h5>
        <p class="tk-default-text tk-sub-heading">
          A verfication code (OTP) will be sent to your Email Id.
        </p>
      </div>
    </div>
    <form autocomplete="off" [formGroup]="forgotPasswordForm" (ngSubmit)="callStatus=true;onSendOTPClick()">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="tk-label" for="email-id">Email Id</label>

            <div class="tk-input-group">
              <input type="email" class="form-control" placeholder="Enter Email Id" formControlName="email" id="email-id">
              <div class="tk-input-border"></div>
            </div>

            <p class="tk-input-error" >
              <sub>
                {{forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').hasError('required') ? 'Please enter Email Id' : forgotPasswordForm.get('email').hasError('emailValidator') ? 'Please enter a valid Email Id' : '&nbsp;'}}
                <!-- {{forgotPasswordForm.get('email').touched && 
                forgotPasswordForm.get('email').hasError('required') ? 
                'Please enter a valid Email Id' : '&nbsp;'}} -->
              </sub> 
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button type="submit" [ngClass]="{ 'disabled-anchor': callStatus == true }" class="btn tk-btn tk-primary-grd-btn" id="send-otp-btn">
            Send OTP
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button (click)="onBackBtnClick()" class="btn tk-btn tk-blue-btn-light" id="back-btn">
            Back
          </button>
        </div>
      </div>

    </form>
  </div>
</div>
