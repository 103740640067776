import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NameToInitialsPipe } from './pipes/name-to-intials-pipe/name-to-initials.pipe';
import { TkBackdropComponent } from './components/tk-backdrop/tk-backdrop.component';
import { TkSwitchSelfAllComponent } from './components/tk-switch-self-all/tk-switch-self-all.component';
import { NullToDashPipe } from './pipes/null-to-dash-pipe/null-to-dash.pipe';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { AlertSidebarComponent } from './components/alert-sidebar/alert-sidebar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CustomDatePipe } from './pipes/custom.datepipe';
// import { PatientInfoCardComponent } from './components/patient-info-card/patient-info-card.component';

// const routes: Routes = [
//   {
//     path: '',
//     component: LandingPageComponent
//   }
// ];

@NgModule({
  declarations: [
    LandingPageComponent,
    LoginComponent,
    ForgotPasswordComponent,
    OtpVerificationComponent,
    ResetPasswordComponent,
    NavbarComponent,
    NameToInitialsPipe,
    TkBackdropComponent,
    TkSwitchSelfAllComponent,
    NullToDashPipe,
    ProfilePageComponent,
    ChatListComponent,
    AlertSidebarComponent,
    CustomDatePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    // BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 2000,
      preventDuplicates: true
    }), // ToastrModule added
    // RouterModule.forChild(routes)
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    LandingPageComponent,
    NavbarComponent,
    NameToInitialsPipe,
    OtpVerificationComponent,
    ResetPasswordComponent,
    ChatListComponent,
    TkBackdropComponent,
    TkSwitchSelfAllComponent,
    NullToDashPipe,
    AlertSidebarComponent,
    CustomDatePipe
  ]
})
export class CoreModule { }
