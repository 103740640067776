import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CallbackRequestService {
  crsId: any;
  constructor(
    private http: HttpClient
  ) {
    this.crsId = localStorage.getItem('userid');
  }

  fetchCallbackrequestList = (pageNoToFetch: any, responseType: any, alertType: any, searchKeyword: any) => {
    const body = {
      crsId: parseInt(this.crsId),
      responseType: responseType,
      searchKeyword: searchKeyword ? searchKeyword : '',
      alertType: alertType,
      pageNo: pageNoToFetch
    };
    return this.http.post('/crs/liveAlerts',
      body
    );
  }

  fetchPatientDetail(patientId) {
    const body = {
      id: patientId ? patientId : '',
    };
    return this.http.post('/patient/getPatientProfile',
      body
    );
  }

  getPatientBasedonCRS = (crsGroup?, crsFilter?) => {
    const body = {
      groupID: crsGroup ? crsGroup.id : 6,
      crsFilter: crsFilter || [],
      keyword: '',
      pagination: {
        pageNo: 0
      }
    };
    return this.http.post('/crs/fetchPatientsWithCrsOfGroup', body);
  }

  getCRSGroupDetails = () => {
    return this.http.post('/crs/getGroupDetail', {});
  }

  fetchAttendingCall = (alertId: any, alertType: any) => {
    const body = {
      alertId: alertId ? alertId : '',
      alertType: alertType ? alertType : "",
    };
    return this.http.post('/patient/updateAlertByAlertId', body);
  }
}
