<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-12 col-lg-6 left-cover-bg hide-on-md-and-down" style="background-image: url('assets/img/login-screen.png')">
      <!-- <img src="assets\img\login-screen.png" alt=""> -->
      <!-- <div id="cover-circle-layer"> -->
        <!-- <img src="assets\img\login-cover-circle-layer.svg" alt=""> -->
      <!-- </div> -->
      <div class="row">
        <div class="col">
          <img src="assets/img/k-linq-icon.svg" id="cover-icon" alt="Klinq">
          <img src="assets/img/K-linq-logo.svg" id="cover-logo" alt="Klinq">
          <h6 class="tk-heading-6">
            CRS Web Portal
          </h6>
          <p>
            “A Kind, Karing & Konnected space to build a life worth living”
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-lg-6 right-pane">
      <ng-container [ngSwitch]="pageMode">
        <ng-container *ngSwitchCase="'forgot-password'">
          <app-forgot-password 
          (emailEntered)="emailEntered = $event"
          (iconMode)="message = $event"
          (changePageMode)="changePageMode($event)">
          </app-forgot-password>
        </ng-container>
        <ng-container *ngSwitchCase="'otp-verification'">
          <app-otp-verification
          [email]="emailEntered"
          [message]="message"
          (uniqueKey)="uniqueKey =$event"
          (backBtnClicked)="changePageMode('forgot-password')"
          (changePageMode)="changePageMode($event)">
          </app-otp-verification>
        </ng-container>
        <ng-container *ngSwitchCase="'reset-password'">
          <app-reset-password
          [uniqueKey]="uniqueKey"
          [mode]="'forgot'"
          (cancelBtnClicked)="changePageMode(null)">
          </app-reset-password>
        </ng-container>
       
        <ng-container *ngSwitchDefault>
          <app-login (login)="login($event)" (changePageMode)="changePageMode($event)">
          </app-login>
        </ng-container>

      </ng-container>
    </div>
  </div>
</div>
