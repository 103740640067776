import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { CommonService } from '../../services/common/common.service';
import { FormsService } from '../../services/forms/forms.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Output() changePageMode: EventEmitter<any>;
  @Output() emailEntered: EventEmitter<any>;
  @Output() iconMode:EventEmitter<any>;
  forgotPasswordForm: FormGroup;
  callStatus:boolean=false;
  intervalSubscription: Subscription;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private formService: FormsService
  ) {
    this.changePageMode = new EventEmitter();
    this.emailEntered = new EventEmitter();
    this.iconMode = new EventEmitter();

    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required,
        this.formService.emailValidator]]

    });
  }

  ngOnInit(): void {
    this.intervalSubscription = interval(8000).subscribe((x) => {
      this.callStatus=false;
     });
  }

  generateOTP = (email: string) => {
    return new Promise((resolve, reject) => {
      this.userService.generateOTP(email).subscribe((res: any) => {
        if (res && res.success) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  onSendOTPClick = () => {
    this.forgotPasswordForm.markAllAsTouched();
    if (this.forgotPasswordForm.valid) {
      this.commonService.toggleSpinner('forgotPassword', true);
      this.generateOTP(this.forgotPasswordForm.value.email).then((res: any) => {
        if (res.success) {          
          this.changePageMode.emit('otp-verification');
          this.emailEntered.emit(this.forgotPasswordForm.value.email);
          this.iconMode.emit(false);
          this.commonService.showToast(res.data, 1);
        }
      }).catch((err) => {
        const message = err.error.message ? err.error.message : null;
        this.commonService.showToast(message, 0);
      }).finally(() => this.commonService.toggleSpinner('forgotPassword', false));
    }
  }

  onBackBtnClick = () => {
    this.changePageMode.emit(null);
  }
}
