import { Component, Input, NgZone, OnInit, TemplateRef } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { AlertSidebarService } from 'src/app/modules/core/services/alertsidebar/alert-sidebar.service';
import { CallbackRequestService } from 'src/app/modules/callback-requests/services/callback-request.service';
import { LiveRequest, LiveRequestMaker } from '../../models/live-request/live-request.model';
import { Patient, PatientMaker } from 'src/app/modules/patients/models/patient.model';
import { PatientsService } from 'src/app/modules/patients/services/patients.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-alert-sidebar',
  templateUrl: './alert-sidebar.component.html',
  styleUrls: ['./alert-sidebar.component.scss']
})
export class AlertSidebarComponent implements OnInit {

  @Input() patient: Patient;

  notificationList: Array<any> = [];
  alertNotificationType: any = 'SELF';
  alertType=[];
  selectedType: any;
  showButton = 1;
  patientList: Array<LiveRequest>;
  patientSelected: Patient;
  crsId: any;
  responseType: any = 'SELF'
  searchKeyword: any = '';
  alertId: any;
  chatId: any;
  attendingResponse: any;
  modalRef: BsModalRef;
  isAllChecked: boolean = true;
  showList:any=[];
  ischecked: boolean = true;
  selectedAlertId:any;
  showLoad = false;
  totalGroupAlertsCount:any = [];
  activePageNo = 0;

  memberFilterList = [
    {
      id: 1,
      name: 'Callback Request',
      value: 'CALLBACKREQUEST',
      isChecked: false
    },
    {
      id: 2,
      name: 'Chat',
      value: 'CHAT',
      isChecked: false
    },    {
      id: 3,
      name: 'Call 911',
      value: 'CALL_911',
      isChecked: false
    },
    {
      id: 4,
      name: 'Hotspot',
      value: 'HOTSPOT',
      isChecked: false
    },
  ];


  constructor(private alertSidebarService: AlertSidebarService,
    private commonService: CommonService,
    private callbackRequestService: CallbackRequestService,
    private patientService: PatientsService,
    private router: Router,
    private zone: NgZone,
    private modalService: BsModalService,


  ) {
    this.attendingResponse = JSON.parse(sessionStorage.getItem("Success"));    
    if (this.attendingResponse == true) {
      this.showButton = 0;
    }
    else {
      this.showButton = 1;
    }
  }

  ngOnInit(): void {
    this.buildNotificationList();
  }

  fetchList = (page?) => {
    return new Promise((resolve, reject) => {
      this.alertSidebarService.fetchNotificationList(this.alertNotificationType, this.alertType, page).subscribe((res: any) => {
        resolve(res);
      }, (err) => reject(err));
    });
  }

  loadMoreData() {
    this.activePageNo++;
    this.buildNotificationList(this.activePageNo);
  }


  buildNotificationList = (pageNumber = 0) => {
    this.commonService.toggleSpinner('NotificationList', true);
    this.activePageNo = pageNumber;
    this.fetchList(pageNumber).then((res: any) => {
      this.totalGroupAlertsCount = res.totalCount;
      if(this.activePageNo === 0){
        this.notificationList = res.data;
      } else {
        this.notificationList = this.notificationList.concat(res.data);
      }

      if (pageNumber < Math.ceil(this.totalGroupAlertsCount / 10) - 1) {
        this.showLoad = true;
      } else {
        this.showLoad = false;
      }
      this.commonService.toggleSpinner('NotificationList', false);
    }).catch(() => {
      this.notificationList = [];
      this.commonService.toggleSpinner('NotificationList', false);
    });
  }

  trackCrsTypeSwitch = (direction) => {
    console.log(direction);
    if (direction == 'left') {
      this.alertNotificationType = 'SELF'
    } else {
      this.alertNotificationType = 'ALL'
    }
    this.buildNotificationList();
  }


  goToCrsPatientInfo(id, alertId, alertType) {
    this.selectedType = alertType;
    this.selectedAlertId=alertId;
    this.selectCallbackPatient(id, alertId);
  }

  goToCrsPatientInfos(id, chatId, alertId, alertType) {
    this.selectedType = alertType;
    this.selectedAlertId=alertId;
    this.selectChatPatient(id, alertId, chatId);
  }


  selectCallbackPatient = (patientId: any, alertId: any) => {
    this.alertId = alertId;

    this.patientService.fetchPatientProfileInfo(patientId).then(
      (list: any) => {
        this.patient = PatientMaker.castInto(list);
        console.log("PATIENT INFO", this.patient)
      }
    );
  }

  selectChatPatient = (patientId: any, chatId: any, alertId: any) => {
    this.chatId = chatId;
    this.alertId = alertId;
    this.patientService.fetchPatientProfileInfo(patientId).then(
      (list: any) => {
        this.patient = PatientMaker.castInto(list);
        console.log(this.patient);
      }
    );
  }

  attendingCall() {
    this.callbackRequestService.fetchAttendingCall(this.alertId, this.alertType).subscribe(
      (res: any) => {
        sessionStorage.setItem("Success", res.success);
        this.commonService.showToast(res.data, 1);
        if (res.success === true) {
          this.showButton = 0
        }
        else {
          this.showButton = 1
        }
      }
    )
  }

  

  openAlertFilterModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
      backdrop: 'static',
    });
  }

  checkAll(status) {
    this.memberFilterList.forEach((e) => {
      e.isChecked = !this.isAllChecked;
      this.getSelectedMemberFilterId(e);
    });
    this.countCheckedRows();
  }

  selectRow(item, event) {
    item.isChecked = event.target.checked;
    this.getSelectedMemberFilterId(item);
    this.countCheckedRows();
  }

  countCheckedRows(): any {
    if (this.memberFilterList.length == 0) {
      return;
    }
    let checkedflag = false;
    let counter = 0;

    this.memberFilterList.forEach((e) => {
      if (!e.isChecked) {
        checkedflag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedflag;
    return counter;
  }
  getSelectedMemberFilterId(e) {
    if(e.isChecked) {
      this.alertType.push(e.value);
    } else {
      let index = this.alertType.indexOf(e.value);
      if(index != -1) {
        this.alertType.splice(index, 1)
      }
    }
  }


  clearSelectedMemberId() {
    this.alertType = [];
    this.memberFilterList.forEach(element => {
      element.isChecked = false;
    });
    this.isAllChecked = true;
    this.modalRef.hide();
    this.showList=[];
    this.buildNotificationList();
  }

  applySelectedFilterMemberId() {
    this.modalRef.hide();
    this.showList=[];
    this.buildNotificationList();
  }

  closeModal(){
    this.modalRef.hide();
    this.clearSelectedMemberId();
  }

  chekTimeout(id) {
    let body = {
      patientId: id ? id : '',
      alertType: 'CHAT',
    };
    this.patientService.requestData('post', 'patient/checkAlertCreationEligibility', body).subscribe((res) => {
      if (res.success) { 
        if (res.data.eligibleToInitiateAlert == false && res.data.chatActive && res.data.chatId==this.chatId) {
          this.zone.run(() => {
            this.router.navigate(['/chat-requests/chat'], {
              queryParams: {patientId: id, chatId: this.chatId},
            });
          });
        } else{
          this.goToCrsPatient(id)
        }
      }
    });
  }

  goToCrsPatient(id) { 
    this.commonService.showSpinner();
    let body = {
      alertId: this.selectedAlertId ? this.selectedAlertId : '',
      alertType: 'CHAT',
    } 
    console.log(body)
    this.patientService
      .requestData('post', 'patient/updateAlertByAlertId', body)
      .subscribe((res) => {
        if (res.success) {
          this.commonService.hideSpinner();
          localStorage.setItem('chatExpiryAlertTime', res.data.chatExpiryAlertTime);
          localStorage.setItem('chatExpiryAlertMessage', res.data.chatExpiryAlertMessage);
          localStorage.setItem('chatExpiryTime', res.data.chatExpiryTime);
          this.zone.run(() => {
            this.router.navigate(['/chat-requests/chat'], {
              queryParams: {patientId: id, chatId: this.chatId},
            });
          });
        } else {
          this.commonService.hideSpinner();
          this.commonService.showToast(res.error.message, 0);
        }
      });
  }

}
