import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tk-switch-self-all',
  templateUrl: './tk-switch-self-all.component.html',
  styleUrls: ['./tk-switch-self-all.component.scss']
})
export class TkSwitchSelfAllComponent implements OnInit {

  direction: string;

  @Output() directionEmitted: EventEmitter<any>;
  constructor() {
    this.direction = 'right';
    this.directionEmitted = new EventEmitter();
   
  }

  ngOnInit(): void {
    this.toggleSwitch();
  }


  toggleSwitch = () => {
    this.direction = this.direction === 'left' ? 'right' : 'left';
    this.directionEmitted.emit(this.direction);
  }

}
