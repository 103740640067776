import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tk-backdrop',
  templateUrl: './tk-backdrop.component.html',
  styleUrls: ['./tk-backdrop.component.scss']
})
export class TkBackdropComponent implements OnInit {

  @Input() transparent: boolean;
  @Output() backdropClicked: EventEmitter<any>;
  constructor() { 
    this.transparent = false;
    this.backdropClicked = new EventEmitter();
  }

  ngOnInit(): void {
  }

  onBackdropClicked = () => {
    this.backdropClicked.emit();
  }
}
