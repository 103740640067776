<ul class="chat-msg-list">
  <li class="chat-msg-item self">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        You
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>

  <li class="chat-msg-item">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
  <li class="chat-msg-item">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
  <li class="chat-msg-item">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
  <li class="chat-msg-item">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
  <li class="chat-msg-item">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
  <li class="chat-msg-item">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
  <li class="chat-msg-item self">
    <div class="chat-msg-wrapper">
      <span class="chat-username">
        Elvis
      </span>
      <p class="chat-msg">
        Hi, I am John Simpson, How may I help you?
      </p>
      <span class="chat-timestamp">
        21:56, 6 Oct 2020
      </span>
    </div>
  </li>
</ul>
