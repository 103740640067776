<div class="row otp-verification-wrapper">
  <div class="col">
    <div class="row">
      <div class="col">
      
        <button *ngIf="message" type="button" class="close right-cornerbtn" aria-label="Close" (click)="clearOTPModal()">
          <span aria-hidden="true">
            <img src="assets/img/close-bg.svg">
          </span>
        </button>
        

        <h5 class="tk-heading-5">One Time Password</h5>
        <p class="tk-default-text tk-sub-heading">
          A verification code has been sent to your email Id, please fill the same in the below input boxes to reset
          your
          password!
        </p>
      </div>
    </div>
    <form autocomplete="off" [formGroup]="otpVerficationForm" (ngSubmit)="onConfirmBtnClick()">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="row otp-input-group-label-wrapper">
              <div class="col">
                <ul class="otp-input-labels">
                  <li>
                    <label class="tk-label">OTP</label>

                  </li>

                  <li>
                    <span class="tk-label" *ngIf="timer > 0">{{timer | date: 'mm:ss' : 'GMT'}}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row otp-input-group-wrapper">
              <div class="col">
                <ul class="otp-input-group" #otpInputBlockRef>
                  <li>
                    <div class="tk-input-group">
                      <input type="tel"
                        [ngClass]="{'otp-error-border': otpError && otpVerficationForm.value.otp0 == null }"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" class="form-control" id="otp-0"
                        formControlName="otp0" maxlength="1" (keyup)="onOTPInput($event, 0)">
                      <div class="tk-input-border"></div>
                    </div>
                  </li>
                  <li>
                    <div class="tk-input-group">
                      <input type="tel"
                        [ngClass]="{'otp-error-border': otpError && otpVerficationForm.value.otp1 == null }"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" class="form-control" id="otp-1"
                        formControlName="otp1" maxlength="1" (keyup)="onOTPInput($event,1)">
                      <div class="tk-input-border"></div>
                    </div>
                  </li>
                  <li>
                    <div class="tk-input-group">
                      <input type="tel"
                        [ngClass]="{'otp-error-border': otpError && otpVerficationForm.value.otp2 == null }"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" class="form-control" id="otp-2"
                        formControlName="otp2" maxlength="1" (keyup)="onOTPInput($event,2)">
                      <div class="tk-input-border"></div>
                    </div>
                  </li>
                  <li>
                    <div class="tk-input-group">
                      <input type="tel"
                        [ngClass]="{'otp-error-border': otpError && otpVerficationForm.value.otp3 == null }"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" class="form-control" id="otp-3"
                        formControlName="otp3" maxlength="1" (keyup)="onOTPInput($event,3)">
                      <div class="tk-input-border"></div>
                    </div>
                  </li>
                  <li>
                    <div class="tk-input-group">
                      <input type="tel"
                        [ngClass]="{'otp-error-border': otpError && otpVerficationForm.value.otp4 == null }"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" class="form-control" id="otp-4"
                        formControlName="otp4" maxlength="1" (keyup)="onOTPInput($event,4)">
                      <div class="tk-input-border"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <sub>
                  {{otpVerficationForm.get('otp0').touched && otpVerficationForm.get('otp0').hasError('required') || otpVerficationForm.get('otp0').hasError('otp0Validator') ? 'Please enter a valid OTP' : '&nbsp;'}}
                  {{otpVerficationForm.get('otp1').touched && otpVerficationForm.get('otp1').hasError('required') || otpVerficationForm.get('otp1').hasError('otp1Validator') ? 'Please enter a valid OTP' : '&nbsp;'}}
                  {{otpVerficationForm.get('otp2').touched && otpVerficationForm.get('otp2').hasError('required') || otpVerficationForm.get('otp2').hasError('otp2Validator') ? 'Please enter a valid OTP' : '&nbsp;'}}
                  {{otpVerficationForm.get('otp3').touched && otpVerficationForm.get('otp3').hasError('required') || otpVerficationForm.get('otp3').hasError('otp3Validator') ? 'Please enter a valid OTP' : '&nbsp;'}}
                </sub> -->
            </div>
            <p class="tk-input-error">
              <sub>
                {{otpValidationMessage? otpValidationMessage : '&nbsp;'}}
              </sub>
            </p>

          </div>
        </div>
      </div>



      <div class="row">
        <div class="col">
          <!--   <button type="submit" class="btn tk-btn tk-primary-grd-btn" [disabled]="timer <= 0" id="confirm-otp-btn">
            Confirm
          </button> -->
          <button type="submit" class="btn tk-btn tk-primary-grd-btn" id="confirm-otp-btn">
            Confirm
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button (click)="clearOTPModal()" type="button" class="btn tk-btn tk-blue-btn-light" aria-label="Close"
            id="back-btn">
            Back
          </button>
        </div>
      </div>

    </form>

    <div class="row">
      <div class="col">
        <label class="tk-label" for="login-username">If you didn’t receive the code yet, then</label> <br>
        <!-- <button (click)="onResendOtp()" class="btn tk-btn tk-blue-btn-light" id="resend-btn"> -->
        <button (click)="onResendOtp()" class="btn tk-btn tk-blue-btn-light" id="resend-btn" [disabled]="timer > 0">
          Resend
        </button>
      </div>
    </div>


  </div>
</div>