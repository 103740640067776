import { Profile } from '../../core/models/profile/profile.model';
import { Tags, TagsMaker } from '../../core/models/tags/tags.model';

export class Crs extends Profile {
  crsID: any;
  tags: Array<any>;
  activeStatusType:any;

  constructor(
    activeStatusType:string,
    id: string,
    firstName: string,
    lastName: string,
    age: string,
    email: string,
    gender: string,
    dob: number,
    crsID: any,
    mobileNo: string,
    address: string,
    tags: Array<Tags>,
    city: any,
    pincode: any,
    state: any,
    country: any,
    gravatarTextColor: any,
    gravatarBgColor: any
  ) {
    super(
      
      id,
      firstName,
      lastName,
      age,
      email,
      gender,
      dob,
      mobileNo,
      address,
      city,
      pincode,
      state,
      country,
      gravatarTextColor,
      gravatarBgColor
    );
    this.crsID = crsID || null;
    this.tags = tags || [];
  }
}

export class CrsMaker {
  static create = () =>
    new Crs(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

  static clone = (obj: Crs) =>
    new Crs(
      obj.activeStatusType,
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.age,
      obj.email,
      obj.gender,
      obj.dob,
      obj.crsID,
      obj.mobileNo,
      obj.address,
      obj.tags,
      obj.city,
      obj.pincode,
      obj.state,
      obj.country,
      obj.gravatarTextColor,
      obj.gravatarBgColor
    );

  static castInto = (obj: any) => {
    let address ;
    let address1 = obj.addressLine1 ? obj.addressLine1 : null;
    let address2 = obj.addressLine2 ? obj.addressLine2 : null;
    address =  address1 + ', ' + address2;
    const tagsList = Array.from(
      new Set(obj.tagsColorResponse.map((e) => JSON.stringify(e)))
    )
      .map((e) => JSON.parse(e.toString()))
      .map((tags: any) =>
        TagsMaker.castInto({
          text: tags.tagName,
          bgColor: tags.colourCode,
          textColor: tags.subColourCode,
        })
      );
    return new Crs(
      obj.activeStatusType,
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.age,
      obj.emailId,
      obj.gender.toLowerCase(),
      new Date(obj.dateOfBirth).getTime(),
      obj.id.toString(),
      obj.mobile,
      address,
      tagsList,
      obj.city,
      obj.pinCode,
      obj.state,
      obj.country,
      obj.subColorTag,
      obj.colorTag
    );
  };
}
