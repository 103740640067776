<ng-container *ngIf="isNavbarMenuOpened">
  <div class="d-lg-none">
    <app-tk-backdrop [transparent]="true" (backdropClicked)="closeNavbarMenu()"></app-tk-backdrop>
  </div>
</ng-container>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src="assets/img/K-linq-logo.svg" id="brand-logo" alt="Klinq">
  </a>
  <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" (click)="toggleNavbarMenu()"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-controls">
    <ul class="">
      <!-- <li class="cursor-pointer">
        <img src="assets/icons/notification-bell.svg" alt="bell" (click)="notificationBell()">
      </li> -->
      <li>
        <span class="user-profile" (click)="toggleProfileMenu()" [ngStyle]="{'background': userData.colourTags}">
          {{userData.firstName + ' ' + userData.lastName | nameToInitials}}
        </span>
        <span class="active-status-position tk-online-status"></span>

        <ng-container *ngIf="isProfileMenuOpened">
          <app-tk-backdrop (backdropClicked)="closeProfileMenu()"></app-tk-backdrop>
        </ng-container>
        <div id="profile-dropdown-list" *ngIf="isProfileMenuOpened">
          <ul>
            <li (click)="goToProfilePage()">
              My Profile
            </li>
            <li (click)="onLogout()">
              Log Out
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>

  <div class="collapse navbar-collapse" [ngClass]="{'show': isNavbarMenuOpened}" id="navbarSupportedContent">

    <ul class="navbar-nav ml-auto">
      <li class="nav-item " [ngClass]="{'active': activeChildUrl == true ? parentUrl == '/'+item.url :  currentRoute === '/'+item.url}" *ngFor="let item of menuList">
        <a class="nav-link" [href]="item.url">{{item.displayName}} <span class="sr-only">(current)</span></a>
      </li>
      <!-- <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li> -->

    </ul>


  </div>

</nav>
<div *ngIf="isNotificationActive" class="sidebar">
  <app-alert-sidebar></app-alert-sidebar>
</div>
