import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameToInitials'
})
export class NameToInitialsPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return null;
    }
    const names = value.split(' ');
    const initials = names.map(e => e[0]);
    return initials.join('');
  }

}
