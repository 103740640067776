import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertSidebarComponent } from './modules/core/components/alert-sidebar/alert-sidebar.component';
import { CoreModule } from './modules/core/core.module';
import { LandingPageComponent } from './modules/core/pages/landing-page/landing-page.component';
import { ProfilePageComponent } from './modules/core/pages/profile-page/profile-page.component';
import { AuthGuard } from './modules/core/services/guard/auth.guard';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', canActivate: [AuthGuard],
    component: LandingPageComponent
  },
  {
    path: 'profile', canActivate: [AuthGuard],
    component: ProfilePageComponent
  },
  {
    path: 'crs', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/crs/crs.module').then(m => m.CrsModule),
  },
  {
    path: 'patients', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/patients-list/patients-list.module').then(m => m.PatientsListModule),
  },
  {
    path: 'hotspots', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/hotspots/hotspots.module').then(m => m.HotspotsModule),
  },
  {
    path: 'callback-requests', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/callback-requests/callback-requests.module').then(m => m.CallbackRequestsModule),
  },
  {
    path: 'chat-requests', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/chat-requests/chat-requests.module').then(m => m.ChatRequestsModule),
  },
  {
    path: 'alerts', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule),
  },
  {
    path: 'notifications', component: AlertSidebarComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    CoreModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
