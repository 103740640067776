import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CommonService } from './modules/core/services/common/common.service';
import { MessagingService } from './modules/core/services/messaging.service';
import { UserService } from './modules/core/services/user/user.service';
import { PatientsService } from './modules/patients/services/patients.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tonya-klinq-web';
  isLoggedIn: boolean;
  currentURL: string;
  message;

  constructor(
    private userService: UserService,
    private router: Router,
    private patientService: PatientsService,
    private messagingService: MessagingService,
    public commonService : CommonService
  ) {
    console.log(commonService.status,"</div>")
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.isLoggedIn = false;
    this.trackLoginState();
    this.userService.checkLoginState();
    this.currentURL = null;
    this.traceRoute();
    interval(2000 * 60).subscribe((x) => {
      let token = this.userService.getUserToken();
      if (token) {
        this.callApi();
      }
    });
    setTimeout(() => {
      let token = this.userService.getUserToken();
      if (token) {
        this.callApi();
      }
    }, 2000);

    interval(5000).subscribe(x => {
      if(this.commonService.status === 'offline'){
      this.retry();
      }
    })
  }
  callApi() {
    let body = {
      id: localStorage.getItem('userid'),
      userType: 'CRS',
      currentLocation: "",
    };
    this.patientService
      .requestData('post', 'patient/updateActiveStatus', body)
      .subscribe((res) => {
        if (res.success) {
          // console.log(res, 'res online');
        } else {
          // console.log(res, 'res offline');
          // this.commonService.showToast(res.error.message, 0);
        }
      });
  }

  trackLoginState = () => {
    this.userService.isLoggedIn.subscribe(state => {
      this.isLoggedIn = state;

      if (!this.isLoggedIn) {
        this.router.navigate(['/']);
      }
    });
  }

  traceRoute = () => {
    this.router.events
      .pipe(
        filter(events => events instanceof NavigationEnd)
      ).subscribe((route: NavigationEnd) => {
        this.currentURL = route.url;
      });
  }

  retry(){
    if(!window.navigator.onLine) {
      this.commonService.status = 'offline';
      console.log("Ofline")
    }else{
      this.commonService.status='online'
      console.log("Online")
    }
  }
}
