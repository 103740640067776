export class Tags {
    id: string;
    text: string;
    bgColor: string;
    textColor: string;

    constructor(
        id: string,
        text: string,
        bgColor: string,
        textColor: string,
    ) {
        this.id = id || null;
        this.text = text || null;
        this.textColor = textColor || '#0EB683';
        this.bgColor = bgColor || this.textColor + '33';
    }
}


export class TagsMaker {
    static create = () => new Tags(null, null, null, null);

    static clone = (obj: Tags) => new Tags(
        obj.id,
        obj.text,
        obj.bgColor,
        obj.textColor,
    )

    static castInto = (obj: any) => new Tags(
        obj.id,
        obj.text,
        obj.bgColor,
        obj.textColor,
    )
}
