<div class="container">
  <div class="row header-wrapper">
    <div class="col">
      <!-- for Back btn -->
      <span>
        <img class="crs-wb-cursor-pointer" src="assets/icons/arrow_back_black.svg" alt="back" (click)="backFn()">
      </span>
      <span class="tk-pane-header">
        My Profile
      </span>
    </div>
  </div>
  <div class="row profile-card-wrapper" *ngIf="profile">
    <div class="col">
      <div class="card">
        <div class="row">
          <!-- col-12 col-md-6 col-lg-2 -->
          <div class="col gravatar-col">
            <div class="row">
              <div class="col">
                <div class="gravatar-wrapper">
                  <span class="profile-gravatar"
                    [ngStyle]="{'backgroundColor': profile.gravatarBgColor, 'color': profile.gravatarTextColor}">
                    {{profile.firstName + ' ' + profile.lastName | nameToInitials}}
                  </span>
                  <span class="tk-online-status"> </span>
                  <!-- <span [ngClass]="{
                    'tk-online-status':
                    profile?.activeStatusType?.toLowerCase() ===
                      'online',
                    'tk-offline-status':
                    profile?.activeStatusType?.toLowerCase() ===
                      'offline'
                  }">
            </span> -->
                </div>
              </div>
            </div>
            <div class="row profile-badge-wrapper">
              <div class="col">
                <span class="tk-badge">
                  CRS
                </span>
              </div>
            </div>
          </div>

          <div class="col-3 ">
            <h5 class="profile-name">
              {{profile.firstName + ' ' + profile.lastName | nullToDash}}
            </h5>
            <ul class="profile-personal-info-list">
              <li>
                {{profile.gender | titlecase}} <span *ngIf="profile.age > 0">, {{profile.age}} Yrs</span>
              </li>
              <li>
                {{profile.dob | date : 'dd MMM yyyy'}}
              </li>
            </ul>
          </div>

          <div class="col-4">


            <div class="row">
              <div class="col">
                <ul class="profile-info-list">
                  <li>
                    <label class="tk-info-label">Id</label>
                    <p class="tk-info-value">{{profile.crsID | nullToDash}}</p>
                  </li>
                  <li>
                    <label class="tk-info-label">Email Id</label>
                    <p class="tk-info-value"> <a class="mail-link" href="mailto:{{profile.email}}">
                        {{profile.email | nullToDash}}
                      </a></p>
                  </li>
                  <li>
                    <label class="tk-info-label">Mobile Number</label>
                    <p class="tk-info-value"><a class="tel-link" href="tel:{{profile.mobileNo}}">
                        {{profile.mobileNo | nullToDash}}
                      </a></p>
                  </li>

                  <li>
                    <label class="tk-info-label">Tags</label>
                    <div class="tk-info-value">

                      <ul class="profile-tags-list">
                        <ng-container *ngFor="let tag of profile.tags">
                          <li>
                            <span class="tk-badge" [ngStyle]="{'backgroundColor': tag.bgColor, 'color': tag.textColor}">
                              {{tag.text | nullToDash}}
                            </span>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </li>

                </ul>
              </div>
              <div class="col">
                <ul class="profile-info-list">
                  <li>
                    <label class="tk-info-label">Address</label>
                    <p class="tk-info-value tk-address mb-0">
                      {{profile?.address +','}}
                    </p>
                    <p class="tk-info-value tk-address">
                      {{profile?.city + ' '+ profile?.pincode +', ' + profile?.state + ', ' + profile?.country |
                      nullToDash}}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-3">
            <button class="btn tk-btn tk-blue-btn-light" (click)="resetPasswordModal.show()" id="reset-password-btn">
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reset Password Popup -->
<ng-template #template (onShow)="changePageMode('otp-verification')">
  <div class="modal-body">
  </div>
</ng-template>

<div class="modal fade reset-password-modal" bsModal #resetPasswordModal="bs-modal" [config]="{backdrop: true,
  ignoreBackdropClick: false}" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name"
  (onShow)="changePageMode('otp-verification')">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">

      <div class="modal-body">
        <ng-container [ngSwitch]="pageMode">
          <ng-container *ngSwitchCase="'otp-verification'">
            <app-otp-verification [email]="profile.email" (uniqueKey)="uniqueKey =$event" [message]="message"
              (backBtnClicked)="resetPasswordModal.hide();pageMode = null" (changePageMode)="changePageMode($event)">
            </app-otp-verification>
          </ng-container>
          <ng-container *ngSwitchCase="'reset-password'">
            <app-reset-password [uniqueKey]="uniqueKey" [mode]="'forgot'" (backBtnClicked)="resetPasswordModal.hide();pageMode = null"
              (changePageMode)="changePageMode('otp-verification')">
            </app-reset-password>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>