import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertSidebarService {
  constructor(
    private http: HttpClient
  ) {
  }

  fetchNotificationList = (alertNotificationType: any, alertType: any, pageNoToFetch: any) => {
    const body = {
      alertNotificationType:alertNotificationType,
      alertType:alertType,
      pagination: {
        pageNo: pageNoToFetch,
      },
    };
    return this.http.post('/crs/getAlertNotificationsList', body);
  }
}
