export const menuItemsArray = [
    {
        icon: null,
        name: 'Work Team Alerts',
        url: 'alerts',
        userType: null,
        activeOn: ['/alerts'],
    },
    {
        icon: null,
        name: 'CRS Work Team Members',
        url: 'crs',
        userType: null,
        activeOn: ['/crs'],
    },
    {
        icon: null,
        name: 'Patients',
        url: 'patients',
        userType: null,
        activeOn: ['/patients'],
    },
    {
        icon: null,
        name: 'Hotspots',
        url: 'hotspots',
        userType: null,
        activeOn: ['/hotspots'],
    },
    {
        icon: null,
        name: 'Callback Requests',
        url: 'callback-requests',
        userType: null,
        activeOn: ['/callback-requests'],
    },
    {
        icon: null,
        name: 'Chat Requests',
        url: 'chat-requests',
        userType: null,
        activeOn: ['/home', '/create-booth'],
    },
    
];
