import { NgZone, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Crs, CrsMaker } from 'src/app/modules/crs/models/crs.model';
import { Profile, ProfileMaker } from '../../models/profile/profile.model';
import { CommonService } from '../../services/common/common.service';
import { UserService } from '../../services/user/user.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  pageMode: any;
  profile: Crs;
  modalRef: BsModalRef;
  message = true;

  uniqueKey: any;
  @ViewChild('resetPasswordModal') public resetPasswordModal: ModalDirective;


  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private commonService: CommonService,
    private _location: Location,
    private zone: NgZone,
  ) {
    this.uniqueKey = null;
    this.pageMode = null;
    this.buildProfile();
  }

  ngOnInit(): void {
  }


  fetchProfileDetails = () => {
    const currentProfileId = JSON.parse(this.userService.getUserDataFromLocal()).userId;
    return new Promise((resolve, reject) => {
      this.userService.getProfileDetails(currentProfileId).subscribe((res: any) => {
        if (res && res.success) {
          resolve(res);
        }
      });
    });
  }

  buildProfile = () => {
    this.fetchProfileDetails().then((res: any) => {
      this.profile = CrsMaker.castInto(res.data);
    });
  }

  openModal = (template: TemplateRef<any>) => {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'reset-password-modal' }));
  }

  changePageMode = (mode) => {
    this.pageMode = mode;
    console.log('RESET');
  }
  backFn() {
    this.zone.run(() => {
      this._location.back();
    });
  }

}
