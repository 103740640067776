import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { UserService } from '../user/user.service';
declare var navigator: any; 

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private commonService: CommonService,
    private userService: UserService
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const token = localStorage.getItem('token');
    let headers = req.headers;

    if (token != null) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    let body: any;
    body = req.body;

    /* if (!body || (body && !(body instanceof FormData))) {
      headers = headers.set(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      );
    } */
    let reqCloned: any;
    const baseUrl = this.commonService.getBaseurl();
    // if (body.apiType == 'upload') {

    reqCloned = req.clone({
      body,
      headers,
      url: `${baseUrl}${req.url}`
    });
    // }
    // else {
    //   reqCloned = req.clone({
    //     headers: headers,
    //     body: body
    //   });
    // }

    if(!window.navigator.onLine) {
      this.commonService.status = 'offline';
      console.log("Ofline")
    }else{
      this.commonService.status='online'
      console.log("Online")
    }
    return next.handle(reqCloned).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!event.body.status) {
            // this.commonService.showToast(event.body.message, 0);
          }
          const resBody: any = event.body;
          if (resBody) {
            let status = resBody.status;
            if (status == undefined) {
              status = event.status == 200 ? 1 : 0;
            }
            if (resBody && resBody.message) {
              // this.commonService.showToast(resBody.message, status);
            }
          }

          return event;
        }
      }),
      catchError((err) => {
        if (err.status === 401 || err.status === 406) {
          //  this.userService.logout();
          this.userService.logout();
        }

        const error = err;
        //  this.commonService.hideSpinner();
        return throwError(error);
      })
    );
  }
}
