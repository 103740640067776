import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginRequest } from '../../models/login-request/login-request.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userData: any;
  isLoggedIn: Subject<boolean>;
  otpSubject: Subject<any>;
  constructor(
    private http: HttpClient
  ) {
    this.isLoggedIn = new Subject();
    this.userData = null;
    this.otpSubject = new Subject();
  }

  updateLoggedInState = (state: boolean) => {
    this.isLoggedIn.next(state);
  }

  login = () => {
    this.updateLoggedInState(true);
    this.userData = this.getUserDataFromLocal();
  }

  checkLoginState = () => {
    if (localStorage.getItem('token')) {
      this.login();
    } else {
      this.logout();
    }
  }

  logout = () => {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    this.updateLoggedInState(false);
  }

  userLogin = (userCreds: LoginRequest) => {
    /* const body = {
      emailId: 'crs1@test.com',
      password: 'Sunday@1234'
    }; */
    const body = {
      emailId: userCreds.username,
      password: userCreds.password,
      crsLoginType:"WEB",
      webFcmToken:localStorage.getItem('webFcmToken')?localStorage.getItem('webFcmToken'):"123456789"
    };

    return this.http.post('/crs/login', body).pipe(tap((res: any) => {
      if (res && res.success) {
        localStorage.setItem('token', res.data ? res.data.token : null);
        localStorage.setItem('userid', res.data.userId);
        if (res.data) {
          this.setUserDataToLocal(JSON.stringify(res.data));
        }
      }
    }));
  }

  isUserAuthenticated = () => {
    return localStorage.getItem('token') ? true : false;
  }

  getUserToken = () => {
    return this.isUserAuthenticated ? localStorage.getItem('token') : null;
  }

  setUserDataToLocal = (data: string) => {
    localStorage.setItem('user', btoa(data));
  }

  getUserDataFromLocal = () => {
    const data = localStorage.getItem('user') || null;
    return atob(data);
  }

  generateOTP = (email: string) => {
    const body = {
      emailId: email,
      userType: 'CRS',
      type: 'CHANGE'
    };
    return this.http.post('/otp/generateOtp', body);
  }

  confirmOTP = (data) => {
    const body = {
      otp: data.otp,
      emailId: data.email
    };
    return this.http.post('/otp/validateOtp', body);
  }

  changePassword = (data: any) => {
    const body = {
      uniqueKey: data.uniqueKey,
      emailId: data.emailId,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword
    };
    const url = this.userData && this.userData.token ? '/crs/changePasswordCrs' : '/crs/forgotPasswordCrs';
    return this.http.post(url, body);
  }

  getProfileDetails = (profileId) => {
    const body = {
      id: profileId
    };

    return this.http.post('/crs/getProfile', body);
  }
}
