export class Profile {
    id: string;
    firstName: string;
    lastName: string;
    age: string;
    email: string;
    gender: string;
    dob: number;
    mobileNo: string;
    address: string;
    city: any;
    pincode: any;
    state: any;
    country: any;

    gravatarTextColor: string;
    gravatarBgColor: string;

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        age: string,
        email: string,
        gender: string,
        dob: number,
        mobileNo: string,
        address: string,
        city: any,
        pincode: any,
        state: any,
        country: any,

        gravatarTextColor?: string,
        gravatarBgColor?: string,
    ) {
        this.id = id || null;
        this.firstName = firstName || null;
        this.lastName = lastName || null;
        this.age = age || null;
        this.email = email || null;
        this.gender = gender || null;
        this.dob = dob || 0;
        this.mobileNo = mobileNo || null;
        this.address = address || '';
        this.city = city || '';
        this.pincode = pincode || '';
        this.state = state || '';
        this.country = country || '';

        this.gravatarTextColor = gravatarTextColor || '#282E34';
        this.gravatarBgColor = gravatarBgColor || '#E1E176';

    }
}


export class ProfileMaker {
    static create = () => new Profile(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    );

    static clone = (obj: Profile) => new Profile(
        obj.id,
        obj.firstName,
        obj.lastName,
        obj.age,
        obj.email,
        obj.gender,
        obj.dob,
        obj.mobileNo,
        obj.address,
        obj.city,
        obj.pincode,
        obj.state,
        obj.country,
        obj.gravatarTextColor,
        obj.gravatarBgColor
    )

    static castInto = (obj: any) => new Profile(
        obj.id,
        obj.firstName,
        obj.lastName,
        obj.age,
        obj.email,
        obj.gender.toLowerCase(),
        new Date(obj.dateOfBirth).getTime(),
        obj.mobileNo,
        obj.Address,
        obj.city,
        obj.pincode,
        obj.state,
        obj.country,
        obj.gravatarTextColor,
        obj.gravatarBgColor
    )
}
