<div *ngIf="commonService.status === 'offline'">
  <div class="col-12 text-center no-internet">
    <div class="d-flex justify-content-center">
      <!-- <img src="assets/img/no-internet-content-new.svg" onerror="this.onerror=null; this.src='assets/img/no-internet-content-new.svg'"  /> -->
    </div>
  </div>
  <div class="col-12 text-left">
    <div class="d-flex justify-content-center">
      <div class="connection-text">
        No Internet!
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="mt-2 mb-5 connection-grey-text">
        Please check your internet connection and try again.
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="secondary-klinq-button btn  btn-success"
        (click)="retry()"
      >
        Retry
      </button>
    </div>
  </div>
</div>
<div *ngIf="commonService.status === 'online'" >
  <div [ngClass]="{'main-container': isLoggedIn && currentURL !== '/'}">
    <app-navbar *ngIf="isLoggedIn && currentURL !== '/'"></app-navbar>
    <router-outlet></router-outlet>
  </div>
  <ngx-spinner bdColor="rgba(255,255,255,0.9)" size="medium" color="#EC7811" type="ball-clip-rotate-pulse">
    <p style="font-size: 20px;color: #EC7811;margin-right: -26px;margin-top: 15px;">Please Wait . . . </p>
  </ngx-spinner>
</div>
