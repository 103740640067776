export class MenuItems {
    id: any;
    icon: string;
    displayName: string;
    url: string;
    activeOn: Array<string>;
    userType: number;


    constructor(
        displayName: any,
        url: any,
        userType: number,
        activeOn: Array<string>,
        icon?: any,
    ) {
        this.id = null;
        this.displayName = displayName || 'link';
        this.url = url || '';
        this.userType = userType;
        this.activeOn = activeOn.map(e => e) || [];
        this.icon = icon || 'menu-default.svg';
    }
}

export class MenuItemsMaker {
    static castInto(obj: any): any {
        return new MenuItems(obj.name, obj.url, obj.userType, obj.activeOn, obj.icon);
    }
}
