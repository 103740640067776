import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() { }

  initTogglePasswordMaskHandler = (
    nodeToObserve,
    callback
  ) => {
    const observer = new MutationObserver(callback);
    const config: MutationObserverInit = {
      attributes: true
    };
    observer.observe(nodeToObserve, config);
    return observer;
  }

  // validation service
  noBlankTest = (val) => {
    return val !== null && val.toString().trim().length > 0;
  }

  validEmailTest = (emailId) => {
    if (!emailId) {
      return false;
    }
    if (!emailId.includes('@')) {
      return false;
    }
    const emailParts = emailId.split('@');
    return emailParts.length > 2;
  }

   emailValidator(control) {
    const email : String = control.value;
    if (!email) {
      return null;
    }

    // if (email !=null && !email.includes("@") || !email.includes(".") ) {
    //   return { emailValidator: true };
    // } else {
    //   return null;
    // }

    //(^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$)

    if (email !=null && email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      return null;
    } else {
      return { emailValidator: true };
    }
  }

   passwordValidator(control) {
    
    const password : String = control.value;

    if (!password) {
      return null;
    }

    if (password !=null && password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)) {
      return null;
    } else {
      return {passwordValidator: true };
    }
  }

  

  otp0Validator(control) {
    const otp0 = control.value;
 
    if (!otp0) {
      return null;
    }

    if (otp0 !=null && otp0.match('^[0-9]{1}$')) {
      return null;
    } else {
      return {otp0Validator: true };
    }
  }

  otp1Validator(control) {
    const otp1 = control.value;

    if (!otp1) {
      return null;
    }

    if (otp1 !=null && otp1.match('^[0-9]{1}$')) {
      return null;
    } else {
      return {otp1Validator: true };
    }
  }

  otp2Validator(control) {
    const otp2 = control.value;

    if (!otp2) {
      return null;
    }

    if (otp2 !=null && otp2.match('^[0-9]{1}$')) {
      return null;
    } else {
      return {otp2Validator: true };
    }
  }

  otp3Validator(control) {
  const otp3 = control.value;

    if (!otp3) {
      return null;
    }

    if (otp3 !=null &&otp3.match('^[0-9]{1}$')) {
      return null;
    } else {
      return {otp3Validator: true};
    }
  }

  maxlengthTest = (val, maxLength) => {
    return val.length <= maxLength;
  }
}
