<div class="row login-form-wrapper">
  <div class="col-12">
    <div class="row">
      <div class="col">
        <h5 class="tk-heading-5">
          Log In to your Account
        </h5>
      </div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()" >
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="tk-label" for="login-username">Email Id</label>
            <div class="tk-input-group">
              <input type="text" placeholder="Enter Email Id" formControlName="username" autocomplete="off"
              class="form-control" id="login-username">
              <div class="tk-input-border"></div>
            </div>
            <p class="tk-input-error" >
              <sub>
                {{loginForm.get('username').touched && loginForm.get('username').hasError('required') ? 'Enter valid Email Id' : loginForm.get('username').hasError('emailValidator') ? 'Enter valid Email Id' : '&nbsp;'}}
              </sub> 

              <!-- <sub *ngIf="
              !loginForm.get('username').hasError('required') && loginForm.get('username').hasError('emailValidator')
            ">
                {{loginForm.get('username').hasError('emailValidator') ? 'Please enter a valid Username' : '&nbsp;'}}
              </sub>  -->
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="tk-label" for="login-password">
              Password</label>

            <div class="tk-input-group">
              <input [type]="show_password ? 'text' : 'password'" placeholder="Enter Password" formControlName="password" autocomplete="off"
              class="form-control" #passwordInputRef id="login-password" >
              <img
                [src]="
                  show_password
                    ? 'assets/icons/show-password.svg'
                    : 'assets/icons/hide-password.svg'
                "
                class="p-3 field-icon cursor-pointer"
                (click)="showPassword()"
              />
              <div class="tk-input-border"></div>
              <!-- <span class="show-password-btn active" (click)="onPasswordMaskIconClick()">
                  <object data="assets/icons/show-pwd.svg" #passwordMaskIconRef 
                  (load)="initPassworkMaskHandler()"
                  type="image/svg+xml"></object>
              </span> -->
            </div>
            <p class="tk-input-error"
            >
            
            <sub>
              {{loginForm.get('password').touched && loginForm.get('password').hasError('required') ? 'Please enter Password' : '&nbsp;'}}
            
            </sub> 

            <!-- <sub>
              {{loginForm.get('password').hasError('passwordValidator') ? 'Please enter a valid Password' : '&nbsp;'}}
            </sub>  -->
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button 
          class="btn tk-btn tk-primary-grd-btn" 
          type="submit"
          id="login-btn">
            Log In
          </button>
        </div>
      </div>


    </form>

    <div class="row">
      <div class="col">
        <label class="tk-label" for="login-username">Forgot your password?</label> <br>
          <button 
          (click)="onRetrieveBtnClick()"
          class="btn tk-btn tk-blue-btn-light" id="retrieve-btn">
            Retrieve
          </button>
      </div>
    </div>
  </div>
</div>
