import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'chatDateTime',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    // var d = new Date(value * 1000);
    // return super.transform(d, 'HH:mm, d MMMM y');
    // GMT Time
    var getDate = new Date(value * 1000);
    // UTC Time
    var hour = getDate.getUTCHours();
    var minute = getDate.getUTCMinutes();
    var date = getDate.getUTCDate();
    var month = new Array(12);
    month[0] = 'January';
    month[1] = 'February';
    month[2] = 'March';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'June';
    month[6] = 'July';
    month[7] = 'August';
    month[8] = 'September';
    month[9] = 'October';
    month[10] = 'November';
    month[11] = 'December';

    var monthName = month[getDate.getUTCMonth()];
    var year = getDate.getUTCFullYear();
    // UTC Time
    // var b = new Date(JSON.parse(value) * 1000).toUTCString();
    // console.log(b, 'b check');

    // return super.transform(b, 'hh:mm, d MMMM y');
    // return super.transform(
    //   getDate,
    //   hour + ':' + minute + ',' +  val
    // );
    return hour + ':' + minute + ',  ' + date + ' ' + monthName + ' ' + year;
  }
}
