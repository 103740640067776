import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user/user.service';
import { FormsService } from '../../services/forms/forms.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit, OnDestroy {

  @Output() changePageMode: EventEmitter<any>;
  @Output() uniqueKey: EventEmitter<any>;
  @Output() backBtnClicked: EventEmitter<any>;
  @ViewChild('otpInputBlockRef') otpInputBlockRef: ElementRef;
  @Output() emailEnt: EventEmitter<any>;

  timer: number;
  timerInterval: any;
  timerIntervalSub: Subscription;

  otpVerficationForm: FormGroup;
  otpValidationMessage: string;
  @Input() message: boolean;
  @Input() email: string;
  otpError: Boolean = false;
  constructor(
    private ngZone: NgZone,
    private fb: FormBuilder,
    private formService: FormsService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private commonService: CommonService

  ) {
    this.otpValidationMessage = null;
    this.changePageMode = new EventEmitter();
    this.uniqueKey = new EventEmitter();
    this.backBtnClicked = new EventEmitter();
    this.timerIntervalSub = new Subscription();
    this.timer = 0;
    this.otpVerficationForm = this.fb.group({

      otp0: [null, Validators.required],
      otp1: [null, Validators.required],
      otp2: [null, Validators.required],
      otp3: [null, Validators.required],
      otp4: [null, Validators.required]

      // OTP Validation
      // otp0: [null, [Validators.required, this.formService.otp0Validator]],
      // otp1: [null, [Validators.required, this.formService.otp1Validator]],
      // otp2: [null, [Validators.required, this.formService.otp2Validator]],
      // otp3: [null, [Validators.required, this.formService.otp3Validator]]
    });
  }

  ngOnInit(): void {
    this.otpVerficationForm.reset(this.otpVerficationForm.value);
    if (this.message == true) {
      this.generateOTP();
    }
    this.restartTimer();
  }

  confirmOTP = (otpEntered: string) => {
    return new Promise((resolve, reject) => {
      this.userService.confirmOTP({
        otp: otpEntered,
        email: this.email
      }).subscribe((res: any) => {
        this.commonService.toggleSpinner('otpconfirm', false);
        if (res && res.success) {
          this.commonService.toggleSpinner('otpconfirm', false);
          resolve(res);
        } else {
          this.commonService.toggleSpinner('otpconfirm', false);
          reject(res);
        }
      }, err => reject(err));
    });
  }

  onConfirmBtnClick = () => {
    //this.focusOTPInputBlock()
    this.otpVerficationForm.markAllAsTouched();
    if (this.otpVerficationForm.valid) {
      this.otpValidationMessage = null;
      this.commonService.toggleSpinner('otpconfirm', true);
      // if (this.timer <= 0) {
      //   this.otpValidationMessage = 'OTP Expired! Please click on Resend Button to generate new OTP.';
      //   return false;
      // }
      const formValues = this.otpVerficationForm.value;
      const otp = (formValues.otp0 || '') + (formValues.otp1 || '') + (formValues.otp2 || '') + (formValues.otp3 || '') + (formValues.otp4 || '');

      if (otp.length < 5) {
        this.otpValidationMessage = 'Please enter a valid OTP.';
        return false;
      }
      this.confirmOTP(otp).then((res: any) => {
        if (res.success) {
          this.uniqueKey.emit({ uniqueKey: res.data.uniqueKey, email: this.email });
          this.changePageMode.emit('reset-password');
          this.otpVerficationForm.reset();
        }
      }).catch((err) => {
        console.log("ERROR", err.error.message);
        const message = err.error.message ? err.error.message : null;
        this.commonService.showToast(message, 0);
        this.otpVerficationForm.reset();
      }).finally(() => {
        this.commonService.toggleSpinner('otpconfirm', false);
      });
    } else {
      this.otpError = true;
      this.commonService.showToast('Please enter a valid OTP', 0);
      // const formValues = this.otpVerficationForm.value;
      this.otpVerficationForm.reset();
    }
  }
  generateOTP = () => {
    return new Promise((resolve, reject) => {
      this.userService.generateOTP(this.email).subscribe((res: any) => {
        if (res && res.success) {
          resolve(res);
          this.commonService.showToast(res.data, 1);
          this.otpVerficationForm.reset();
          this.restartTimer();
        } else {
          // reject('null');
          this.commonService.showToast(res.error.message, 0);
          this.otpVerficationForm.reset();
        }
      }, (err) => {
        reject(err);
        this.commonService.showToast(err.error.message, 0);
      });
    });
  }

  onResendOtp = () => {
    this.generateOTP();
    // this.restartTimer();
  }


  // OTP INPUT CURSOR HANDLER

  onOTPInput = (event, index) => {
    event.preventDefault();
    this.ngZone.runOutsideAngular(() => {
      const target = event.target;
      if (event.key === 'Backspace'
        && target.value.length <= 0
        && index > 0
      ) {
        // move backwards
        this.focusOTPInputBlock(--index);
      } else {
        if (target.value.length === target.maxLength && index < 4) {
          // move forwards
          this.focusOTPInputBlock(++index);
        }
      }
    });
  }

  focusOTPInputBlock = (index) => {
    const target = this.otpInputBlockRef.nativeElement.querySelector('#otp-' + index) as HTMLElement;
    target.focus();
  }

  // Timer
  setupTimer = (mins) => {
    this.timer = 60 * 1000 * mins;
    this.timerInterval = interval(1000);
  }

  startTimer = () => {
    this.timerIntervalSub = this.timerInterval.subscribe(time => {
      this.timer = this.timer - 1000;
      if (this.timer <= 0) {
        this.stopTimer();
      }
    });
  }

  stopTimer = () => {
    if (this.timerIntervalSub) {
      this.timerIntervalSub.unsubscribe();
    }
  }

  restartTimer = () => {
    this.setupTimer(1.50);
    if (!this.timerIntervalSub.closed) {
      this.stopTimer();
    }
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }


  clearOTPModal() {
    this.backBtnClicked.emit();
  }
}
