import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { FormsService } from '../../services/forms/forms.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Output() cancelBtnClicked: EventEmitter<any>;
  @Output() backBtnClicked: EventEmitter<any>;
  @Input() uniqueKey: string;
  @Input() mode: string;
  resetPasswordForm: FormGroup;
  uniqueData: any = {};
  toastMessage: any = {};
  show_password: boolean = false;
  show_confirm_password: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private formService: FormsService,
    private router: Router,
  ) {

    this.cancelBtnClicked = new EventEmitter();
    this.backBtnClicked = new EventEmitter();
    this.uniqueKey = null;
    this.mode = 'change';
    this.resetPasswordForm = this.fb.group({
      newPassword: [null, [Validators.required,
      this.formService.passwordValidator]],
      reTypePassword: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.uniqueData = this.uniqueKey;
  }

  setNewPassword = () => {

  }

  onSaveBtnClick = () => {

    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.valid) {
      this.commonService.toggleSpinner('resetPassword', true);
      this.resetPassword(this.resetPasswordForm.value.newPassword, this.resetPasswordForm.value.reTypePassword).then((res) => {
        this.commonService.toggleSpinner('resetPassword', false);
        this.toastMessage = res;
        this.commonService.showToast(this.toastMessage.data, 1);
        this.onCancelBtnClick();
      }).catch((err) => {
        if(err.error.code==402){
          const message = err.error.message ? err.error.message : null;
          this.commonService.toggleSpinner('resetPassword', false);
          this.commonService.showToast(message, 0);     
          this.onCancelBtnClick();
        }
        else{
          this.commonService.hideSpinner();
          this.commonService.showToast(err.error.message, 0);
        }
       
      }).finally(() => {
        this.commonService.toggleSpinner('resetPassword', false);
      });
    }

  }


  resetPassword = (newPassword: string, reTypePassword: string) => {
    return new Promise((resolve, reject) => {
      this.userService.changePassword({
        uniqueKey: this.uniqueData.uniqueKey,
        emailId: this.uniqueData.email,
        newPassword: newPassword,
        confirmPassword: reTypePassword
      }).subscribe((res: any) => {
        if (res && res.success) {
          resolve(res);
        } else {
          reject(res);
        }
      }, err => reject(err));
    });
  }
  onCancelBtnClick = () => {
    this.cancelBtnClicked.emit();
    this.backBtnClicked.emit();
    this.resetPasswordForm.reset();
  }

  showPassword() {
    this.show_password = !this.show_password;
  }

  showConfirmPassword() {
    this.show_confirm_password = !this.show_confirm_password;
  }
}
