import { Profile } from '../../core/models/profile/profile.model';
import { Tags, TagsMaker } from '../../core/models/tags/tags.model';

export class Patient extends Profile {
  patientID: any;
  crsAssigned: Tags;
  haveHealthInsurance: boolean;
  ssn: string;
  description: string;
  activeStatusType: string;
  haveHealthInsuranceNumber:any

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    age: string,
    email: string,
    gender: string,
    dob: number,
    patientID: any,
    mobileNo: string,
    address: string,
    city: any,
    pincode: any,
    state: any,
    country: any,
    crsAssigned?: Tags,
    haveHealthInsurance?: boolean,
    haveHealthInsuranceNumber?:any,
    ssn?: string,
    description?: string,
    activeStatusType?: string
  ) {
    super(
      id,
      firstName,
      lastName,
      age,
      email,
      gender,
      dob,
      mobileNo,
      address,
      city,
      pincode,
      state,
      country
    );
    this.patientID = patientID || null;
    this.crsAssigned = crsAssigned || null;

    this.haveHealthInsurance = haveHealthInsurance || false;
    this.haveHealthInsuranceNumber = haveHealthInsuranceNumber || '';

    this.ssn = ssn || null;
    this.description = description || null;
    this.activeStatusType = activeStatusType || null;
  }
}

export class PatientMaker {
  static create = () =>
    new Patient(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

  static clone = (obj: Patient) =>
    new Patient(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.age,
      obj.email,
      obj.gender,
      obj.dob,
      obj.patientID,
      obj.mobileNo,
      obj.address,
      obj.city,
      obj.pincode,
      obj.state,
      obj.country,
      TagsMaker.clone(obj.crsAssigned),
      obj.haveHealthInsurance,
      obj.haveHealthInsuranceNumber,
      obj.ssn,
      obj.description,
      obj.activeStatusType
    );

  static castInto = (obj: any) => {
    let address ;
    let address1 = obj.addressLine1 ? obj.addressLine1 : null;
    let address2 = obj.addressLine2 ? obj.addressLine2 : null;
    address =  address1 + ', ' + address2;
    console.log(address);
    return new Patient(
      obj.id,
      obj.firstName,
      obj.lastName,
      obj.age,
      obj.emailId,
      obj.gender.toLowerCase(),
      new Date(obj.dateOfBirth).getTime(),
      obj.id.toString(),
      obj.mobile,
      address,
      obj.city,
      obj.pinCode,
      obj.state,
      obj.country,
      TagsMaker.castInto({
        text: obj.correspondingCrsName,
        bgColor: obj.crsColourTag,
        textColor: obj.crsSubColorTag,
      }),
      obj.healthInsurance ? obj.healthInsurance.toLowerCase() === 'yes' : false,
      obj.healthInsuranceNumber ? obj.healthInsuranceNumber : '',
      obj.socialSecurityNumber,
      obj.description,
      obj.activeStatusType
    );
  };

  static castIntoForCRSList = (obj: any) => {
    const name = obj.patientName ? obj.patientName.split(' ') : '  ';
    let address = obj.addressLine1 || null;
    address = ' ' + obj.addressLine2 ? obj.addressLine2 : null;
    return new Patient(
      obj.id,
      name[0],
      name[1],
      obj.age,
      obj.emailId,
      obj.gender.toLowerCase(),
      new Date(obj.dateOfBirth).getTime(),
      obj.id.toString(),
      obj.mobile,
      address,
      obj.city,
      obj.pinCode,
      obj.state,
      obj.country,
      TagsMaker.castInto({
        text: obj.correspondingCrsName,
        bgColor: obj.crsColourTag,
        textColor: obj.crsSubColorTag,
      }),
      obj.healthInsurance ? obj.healthInsurance.toLowerCase() === 'yes' : false,
      obj.healthInsuranceNumber ? obj.healthInsuranceNumber : '',
      obj.socialSecurityNumber,
      obj.description,
      obj.activeStatusType
    );
  };

  static castIntoForPatientList = (obj: any) => {
    const name = obj.patientName ? obj.patientName.split(' ') : '  ';
    let address = obj.addressLine1 || null;
    address = ' ' + obj.addressLine2 ? obj.addressLine2 : null;
    return new Patient(
      obj.id,
      name[0],
      name[1],
      obj.age,
      obj.emailId,
      obj.gender.toLowerCase(),
      new Date(obj.dateOfBirth).getTime(),
      obj.id.toString(),
      obj.mobile,
      address,
      obj.city,
      obj.pinCode,
      obj.state,
      obj.country,
      TagsMaker.castInto({
        text: obj.crsName,
        bgColor: obj.crsColourTag,
        textColor: obj.crsSubColorTag,
      }),
      obj.healthInsurance ? obj.healthInsurance.toLowerCase() === 'yes' : false,
      obj.healthInsuranceNumber ? obj.healthInsuranceNumber : '',

      obj.socialSecurityNumber,
      obj.description,
      obj.activeStatusType
    );
  };
}
