import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientsService } from 'src/app/modules/patients/services/patients.service';
import { CommonService } from '../../services/common/common.service';
import { UserService } from '../../services/user/user.service';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  emailEntered: string;
  pageMode: string;
  uniqueKey: string;
  constructor(
    private userService: UserService,
    private router: Router,
    private commonService: CommonService,
    private patientService: PatientsService
  ) {
    this.emailEntered = null;
    this.uniqueKey = null;
    this.pageMode = null;
  }

  ngOnInit(): void {
    this.userService.isLoggedIn.subscribe(state => {
      if (state) {
        this.router.navigate(['/', 'alerts']);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  changePageMode = (mode) => {
    this.pageMode = mode;
  }

  onLogin = (loginForm) => {
    return new Promise((resolve, reject) => {
      this.userService.userLogin(loginForm).subscribe((res: any) => {
        console.log(res);
        if (res && res.success) {
          resolve(res.data);
        } else {
          reject(res.error);
        }
      }, err => reject(err));
    });
  }

  login = (loginForm) => {
    this.commonService.toggleSpinner('login', true);
    this.onLogin(loginForm).then((data) => {
      this.userService.login();
      this.callApi();
      this.commonService.showToast('You have logged in successfully', 1);
    }).catch((err) => {
      console.log('ERROR');
      const message =  err ? err.message : null;
      this.commonService.showToast(message, 0);
    }).finally(() => {
      this.commonService.toggleSpinner('login', false);
    });
  }

  callApi() {
    let body = {
      id: localStorage.getItem('userid'),
      userType: 'CRS',
      currentLocation: "",
    };
    this.patientService
      .requestData('post', 'patient/updateActiveStatus', body)
      .subscribe((res) => {
        if (res.success) {
          // console.log(res, 'res online');
        } else {
          // console.log(res, 'res offline');
          // this.commonService.showToast(res.error.message, 0);
        }
      });
  }

}
