import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullToDash'
})
export class NullToDashPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value || value.toString().trim().length === 0) {
      return '-';
    }
    return value;
  }

}
