export class LoginRequest {
    id: string;
    username: string;
    password: string;

    constructor(
        id,
        username,
        password
    ) {
        this.id = id || null;
        this.username = username || null;
        this.password = password || null;
    }
}

export class LoginRequestMaker {
    static create = () => new LoginRequest(null,null,null);
    static clone = (obj: LoginRequest) => new LoginRequest(obj.id, obj.username,obj.password);
    static castIntoFromReactiveForm = (obj: any) => new LoginRequest(obj.id, obj.username,obj.password);
}
