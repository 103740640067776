import { Injectable } from '@angular/core';
import { Duration } from 'ngx-bootstrap/chronos/duration/constructor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loaderSubject: BehaviorSubject<any>;
  loaderQueue: Array<any>;
  selectedPatient:any;
  selectedTab:number=0;
  status = 'online';

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.loaderSubject = new BehaviorSubject(this.loaderQueue);
    this.activateLoaderHandler();
    this.loaderQueue = [];
  }

  getEmbededSVGRef = (objectRef) => {
    const objRef = objectRef.contentDocument;
    return objRef.querySelector('svg');
  }


  getBaseurl = () => environment.baseUrl;

  toggleBodyScroll = (state) => {
    if (state) {
      document.body.classList.add('lockScroll');
    } else {
      if (document.body.classList.contains('lockScroll')) {
        document.body.classList.remove('lockScroll');
      }
    }
  }


  showSpinner = () => {
    this.spinner.show();
  }
  hideSpinner = () => {
    this.spinner.hide();
  }

  activateLoaderHandler = () => {
    this.loaderSubject.subscribe(res => {
      if (res && res.length > 0) {
        this.showSpinner();
      } else {
        this.hideSpinner();
      }
    });
  }

  toggleSpinner = (key, active) => {
    const toChange = this.loaderQueue.indexOf(key);
    if (active && toChange === -1) {
      this.loaderQueue.push(key);
    } else {
      if (toChange >= 0) {
        this.loaderQueue.splice(toChange, 1);
      }
    }
    const newQueue = Array.from(this.loaderQueue);
    this.loaderSubject.next(newQueue);
  }

  showToast = (message, status, title?) => {
    if (!message) {
      if (!status) {
        title = 'Something went wrong';
        message = 'Please try again';
        status = 3;
      }
      else {
        title = null;
        message = 'Success!!';
      }
    }
    switch (status) {
      case 0:
        this.toastr.error(message, title ? title : null);
        break;
      case 1:
        this.toastr.success(message, title ? title : null);
        break;
      case 2:
        this.toastr.info(message, title ? title : null);
        break;
      default:
        this.toastr.warning(message, title ? title : null);
        break;
    }
  }
}
