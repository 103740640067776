<div class="row reset-password-wrapper">
  <div class="col">
    <div class="row">
      <div class="col">
        <h5 class="tk-heading-5">Reset Password</h5>
        <p class="tk-default-text tk-sub-heading">
          In order to change the password, fill the below fields and click on the save button.
        </p>
      </div>
    </div>
    <form autocomplete="off" [formGroup]="resetPasswordForm" (ngSubmit)="onSaveBtnClick()">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="tk-label" for="new-pwd">Enter Password</label>

            <div class="tk-input-group">
              <input [type]="show_password ? 'text' : 'password'" placeholder="Enter password" formControlName="newPassword" class="form-control" id="new-pwd">
              <img
                [src]="
                  show_password
                    ? 'assets/icons/show-password.svg'
                    : 'assets/icons/hide-password.svg'
                "
                class="p-3 field-icon cursor-pointer"
                (click)="showPassword()"
              />
              <div class="tk-input-border"></div>
            </div>
            <p class="tk-input-error" >
              <sub>
                {{resetPasswordForm.get('newPassword').touched && resetPasswordForm.get('newPassword').hasError('required') ? 'Please enter Password' : resetPasswordForm.get('newPassword').hasError('passwordValidator') ? 
                'Minimum 8 letters, numbers, symbols, upper and lower case.' : '&nbsp;'}}
                <!-- {{resetPasswordForm.get('newPassword').touched && 
                resetPasswordForm.get('newPassword').hasError('required') ? 
                'Please enter a valid Password' : '&nbsp;'}} -->
              </sub> 
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="tk-label" for="retype-new-pwd">Confirm Password</label>

            <div class="tk-input-group">
              <input [type]="show_confirm_password ? 'text' : 'password'" placeholder="Confirm password" formControlName="reTypePassword" class="form-control" id="retype-new-pwd">
              <img
                [src]="
                show_confirm_password
                    ? 'assets/icons/show-password.svg'
                    : 'assets/icons/hide-password.svg'
                "
                class="p-3 field-icon cursor-pointer"
                (click)="showConfirmPassword()"
              />
              <div class="tk-input-border"></div>
            </div>
            <p class="tk-input-error" *ngIf="resetPasswordForm.get('reTypePassword').value">
              <sub>
                {{resetPasswordForm.get('reTypePassword').touched && 
                resetPasswordForm.get('reTypePassword').hasError('required') ? "Please enter new password"  : resetPasswordForm.get('newPassword').value !=
                resetPasswordForm.get('reTypePassword').value ?
                 "Password doesn't matched" : '&nbsp;'}}
              </sub> 
            </p>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <button type="submit" class="btn tk-btn tk-primary-grd-btn" id="send-otp-btn">
            Save
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button (click)="onCancelBtnClick()" class="btn tk-btn tk-blue-btn-light" id="back-btn">
            Cancel
          </button>
        </div>
      </div>

    </form>
  </div>
</div>
