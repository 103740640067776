import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, retryWhen, delay } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  connection$: WebSocketSubject<any>;


  constructor(
    private http: HttpClient
  ) {
  }

  fetchCall911HistoryList = (pageNoToFetch: any, patientId, formatStartDate, formatEndDate) => {
    const body = {
      pagination: {
        pageNo: pageNoToFetch
      },
      export: {
        fromDate: '',
        toDate: ''
      },
      patientId: patientId,
      fromDate: formatStartDate ? formatStartDate : null,
      toDate: formatEndDate ? formatEndDate : null,

    };
    return this.http.post('/crs/getCall911History', body);
  }

  fetchSearchHistoryList = (pageNoToFetch: any, patientId, formatStartDate, formatEndDate, selectedSearchHistoryFilterId) => {
    const body = {
      filterSearch: {
        startDate: formatStartDate ? formatStartDate : null,
        endDate: formatEndDate ? formatEndDate : null,
        listOfKeyWords: selectedSearchHistoryFilterId ? selectedSearchHistoryFilterId : []
      },
      patientId: patientId,
      pageNo: pageNoToFetch,
      export: {
        fromDate: '',
        toDate: ''
      }
    };
    return this.http.post('/crs/getSearchHistory', body);
  }

  fetchLoginHistoryList = (pageNoToFetch: any, patientId, formatStartDate, formatEndDate) => {
    const body = {
      pagination: {
        pageNo: pageNoToFetch
      },
      export: {
        fromDate: '',
        toDate: ''
      },
      patientId: patientId,
      fromDate: formatStartDate ? formatStartDate : null,
      toDate: formatEndDate ? formatEndDate : null,
    };
    return this.http.post('/crs/getLoginHistory', body);
  }

  fetchHotspotHistoryList = (pageNoToFetch: any, patientId, formatStartDate, formatEndDate, selectedHotspotStatusFilterId, search) => {
    const body = {
      patientID: patientId,
      pagination: {
        pageNo: pageNoToFetch
      },
      filter: {
        hotspotType: selectedHotspotStatusFilterId ? selectedHotspotStatusFilterId : [],
        fromDate: formatStartDate ? formatStartDate : null,
        toDate: formatEndDate ? formatEndDate : null,
        searchKeyword: search ? search : ''
      }
    };
    return this.http.post('/crs/fetchPatientHotspotHistory', body);
  }

  fetchCallBackHistoryList = (pageNoToFetch: any, patientId, formatStartDate, formatEndDate, selectedCallbackStatusFilterId, search) => {
    const body = {
      filterHistoryRequest: {
        crsId: search ? search : '',
        startDate: formatStartDate ? formatStartDate : null,
        endDate: formatEndDate ? formatEndDate : null,
        status: selectedCallbackStatusFilterId ? selectedCallbackStatusFilterId : []
      },
      pagination: {
        pageNo: pageNoToFetch
      },
      patientId: patientId,
      historyType: "ALL"
    };
    return this.http.post('/crs/getCallBackHistory', body);
  }

  fetchWorksiteHubHistoryList = (pageNoToFetch: any,patientId, formatStartDate, formatEndDate, search) => {
    const body = {
      pagination: {
        pageNo: pageNoToFetch
      },
      filterSearchRequest: {
        startDate: formatStartDate ? formatStartDate : null,
        endDate: formatEndDate ? formatEndDate : null,
        keyword: search ? search : ''
      },
      export: {
        fromDate: '',
        toDate: ''
      },
      patientId: patientId,

    };
    return this.http.post('/crs/getWorkSiteHubHistory', body);
  }

  fetchChatHistoryList = (pageNoToFetch: any, patientId, formatStartDate, formatEndDate, selectedChatStatusFilterId, search) => {
    const body = {
      pagination: {
        pageNo: pageNoToFetch
      },
      patientId: patientId,
      historyType: 'ALL',
      filterHistoryRequest: {
        startDate: formatStartDate ? formatStartDate : null,
        endDate: formatEndDate ? formatEndDate : null,
        status: selectedChatStatusFilterId ? selectedChatStatusFilterId : [],
        crsId: search ? search : null,

      }
    };
    return this.http.post('/crs/getChatHistory', body);
  }

  private fetchPatientProfile = (patientId?) => {
    const body = {
      id: patientId
    };
    return this.http.post('/patient/getPatientProfile', body);
  }
  fetchPatientProfileInfo = (id?) => {
    return new Promise((resolve, reject) => {
      this.fetchPatientProfile(id).subscribe((res: any) => {
        if (res && res.success) {
          resolve(res.data);
        } else {
          reject(res);
        }
      }, err => reject(err));
    });
  }

  fetchPatientRemarkList = () => {
    const body = {
      patientID: 1,
      pagination: {
        pageNo: 0
      },
      filter: {
        search: '',
        crsIds: [14],
        fromDate: '2020-12-02',
        toDate: '2020-12-29',
        attachment: false
      }
    };

    return this.http.post('/crs/fetchAllPatientRemark', body);
  }

  requestData(
    type: string,
    api: string,
    body: any = '',
    apiType?
  ): Observable<any> {
    if (type == 'post') {
      return this.http.post('/' + api, body);
    }
    return;
  }

  trackPatientLocationRealtime = () => {
  }

}
