import { Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/modules/core/services/common/common.service';
import { FormsService } from 'src/app/modules/core/services/forms/forms.service';
import { LoginRequest, LoginRequestMaker } from '../../models/login-request/login-request.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('passwordInputRef') passwordInputRef: ElementRef;
  @ViewChild('passwordMaskIconRef') passwordMaskIconRef: ElementRef;

  @Output() login: EventEmitter<any>;
  @Output() changePageMode: EventEmitter<any>;

  passwordMaskObserver: MutationObserver;

  loginRequest: LoginRequest;
  loginForm: FormGroup;
  show_password: boolean = false;
  constructor(
    private formService: FormsService,
    private commonService: CommonService,
    private ngZone: NgZone,
    private fb: FormBuilder
  ) {
    this.changePageMode = new EventEmitter();
    this.login = new EventEmitter();

    this.loginRequest = LoginRequestMaker.create();
    this.loginForm = this.fb.group({
      // username: [null, [Validators.required, Validators.pattern(this.emailPatternTest()), Validators.maxLength(10)]],
      username: [null, [Validators.required,
        this.formService.emailValidator
      ]],
      password: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  togglePasswordMask = (mutationsList) => {
    if (!this.passwordMaskIconRef || !this.passwordInputRef) {
      return false;
    }

    // Use traditional 'for loops' for IE 11
    this.ngZone.runOutsideAngular(() => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          const passwordMaskSVG = this.commonService.getEmbededSVGRef(this.passwordMaskIconRef.nativeElement);
          const passwordInputType = this.passwordInputRef.nativeElement.getAttribute('type');
          if (passwordInputType === 'text' &&
            !passwordMaskSVG.classList.contains('active')) {
            passwordMaskSVG.classList.add('active');
          } else {
            if (passwordMaskSVG.classList.contains('active')) {
              passwordMaskSVG.classList.remove('active');
            }
          }
        }
      }
    })
  }

  onPasswordMaskIconClick = () => {
    const inputRef = this.passwordInputRef.nativeElement;
    const inputType = inputRef.getAttribute('type');

    this.ngZone.runOutsideAngular(() => {
      inputRef.setAttribute('type', inputType.toLowerCase() === 'text' ? 'password' : 'text');
    });
  }

  initPassworkMaskHandler = () => {
    const iconRef = this.passwordMaskIconRef.nativeElement;
    if (iconRef) {
      this.passwordMaskObserver = this.formService.initTogglePasswordMaskHandler(
        this.passwordInputRef.nativeElement,
        this.togglePasswordMask
      );
    }
  }

  onRetrieveBtnClick = () => {
    this.changePageMode.emit('forgot-password');
  }

  onLogin = () => {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
    this.loginRequest = LoginRequestMaker.castIntoFromReactiveForm(this.loginForm.value);
    this.login.emit(this.loginRequest);
    }
  }

  showPassword() {
    this.show_password = !this.show_password;
  }

  ngOnDestroy(): void {

    if (this.passwordMaskObserver) {
      this.passwordMaskObserver.disconnect();
    }
  }


}
